import React from 'react'
import { styles } from './styles'
import classnames from 'classnames'

interface InputProps {
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
  type: string
  placeholder: string
  valid?: boolean
  required?: boolean
  error?: boolean
  customStyles?: any
  onKeyPress?: Function
  elementRef?: any
  disabled?: boolean
  autoComplete?: string
}

const SignInInput = ({
  value,
  onChange,
  type,
  placeholder,
  valid,
  error,
  customStyles,
  onKeyPress,
  elementRef,
  disabled,
  autoComplete,
  ...props
}: InputProps) => {
  const classes = styles()
  const buttonClasses = classnames({
    [classes.input]: true,
    [customStyles]: !!customStyles,
    [classes.error]: error,
    [classes.disabled]: disabled,
  })

  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={buttonClasses}
      disabled={disabled}
      ref={elementRef}
      autoComplete={autoComplete}
      {...props}
    />
  )
}

export default SignInInput
