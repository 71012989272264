import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import { ROUTES } from '../../constants'
import { styles } from './styles'
import 'lazysizes'
import cookiesLogo from '../../assets/images/cookies.svg'
import CHROutlinedButton from '../outlinedButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { setItemToLocalStorage } from '../../../utils/setLocalStorage'

const CookieBanner = () => {
  const classes = styles()
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const [shouldHide, setShouldHide] = useState(
    JSON.parse(window.localStorage.getItem('cookie_consent'))
  )

  const acceptCookie = () => {
    setShouldHide(true)
    setItemToLocalStorage('cookie_consent', 'true')
  }

  if (shouldHide) return null

  return (
    <div className={classes.cookieBannner}>
      {!isPhone ? (
        <div className={classes.container}>
          <div className={classes.centerWrapper}>
            <img
              src={cookiesLogo}
              className={classes.logo + ' lazyload '}
              alt="coockie logo"
            />
            <div className={classes.centerText}>
              <Typography variant="h3" className={classes.text}>
                we use cookies
              </Typography>
              <Link to={ROUTES.privacy} className={classes.link}>
                <Typography variant="subtitle1" className={classes.linkText}>
                  view our privacy policy
                </Typography>
              </Link>
            </div>
          </div>
          <div className={classes.button}>
            <CHROutlinedButton
              onClick={acceptCookie}
              label="i'm ok with that"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 230,
            width: '100%',
            margin: 'auto',
          }}
        >
          <div style={{ display: 'flex' }}>
            <img
              src={cookiesLogo}
              className={classes.logo + ' lazyload '}
              alt="coockie logo"
            />
            <Typography variant="h3" className={classes.text}>
              we use cookies
            </Typography>
          </div>
          <div className={classes.button}>
            <CHROutlinedButton
              onClick={acceptCookie}
              label="i'm ok with that"
            />
          </div>
          <Link to={ROUTES.privacy} className={classes.link}>
            <Typography variant="subtitle1" className={classes.linkText}>
              view our privacy policy
            </Typography>
          </Link>
        </div>
      )}
    </div>
  )
}

export default CookieBanner
