import { detect } from 'detect-browser'
import { useCallback, useState } from 'react'

const useNavigateToExtStore = (): [boolean, Function, Function] => {
  let [isExtWarningModalOpen, setExtWarningModalOpen] = useState(false)

  const navigateToStore = useCallback(() => {
    const browser = detect()

    switch (browser && browser.name) {
      case 'chrome':
      case 'edge-chromium':
        window.open(process.env.GATSBY_CHROME_EXTENSION_URL, '_blank')
        break

      case 'crios':
      case 'ios':
        window.open(process.env.GATSBY_IOS_SAFARI_EXTENSION_URL, '_blank')
        break

      case 'safari':
        if (Number(browser?.version?.split('.')[0]) < 14) {
          setExtWarningModalOpen(true)
          return
        }
        window.open(process.env.GATSBY_SAFARI_EXTENSION_URL, '_blank')
        break

      default:
        window.open(process.env.GATSBY_CHROME_EXTENSION_URL, '_blank')
    }
  }, [])

  return [isExtWarningModalOpen, setExtWarningModalOpen, navigateToStore]
}

export default useNavigateToExtStore
