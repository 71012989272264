import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { styles } from './styles'
// import ContentContainer from '../../components/contentContainer'
import { Link } from 'gatsby'
import brandsIcon from '../../assets/images/brands.svg'
import axios from '../../axios'
import { ENDPOINTS, SHOP_MENU_CONFIG } from '../../constants'

interface DropdownMenuProps {
  t: TFunction
  handleClose: () => void
  openStatus: boolean
}

const DropdownMenu = ({ t, openStatus, handleClose }: DropdownMenuProps) => {
  const classes = styles()

  const [categories, setCategories] = useState()
  const [featuredBrands, setFeaturedBrands] = useState()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const [categoriesData, featuredBrandsData] = await Promise.all([
          axios.get(ENDPOINTS.categories),
          axios.get(ENDPOINTS.featuredBrands),
        ])
        setCategories(categoriesData.data.data)
        setFeaturedBrands(featuredBrandsData.data.data.brands)
        setIsPageLoading(false)
      } catch (err) {
        setIsError(true)
        setIsPageLoading(false)
      }
    })()
  }, [])

  return (
    <Box>
      {openStatus && (
        <Box className={classes.container}>
          <div className={classes.shopMenuContainer}>
            {Object.values(SHOP_MENU_CONFIG).map(item => {
              return (
                <div className={classes.shopMenuColContainer}>
                  <Link
                    onClick={handleClose}
                    className={classes.shopMenuLink}
                    to={`/category/${item.title}`}
                  >
                    <Typography
                      className={classes.shopMenuHeading}
                      variant="h1"
                      component="p"
                    >
                      {t(`shared.${item.title}`)}
                    </Typography>
                  </Link>
                  <div
                    className={[
                      classes.shopMenuItemIcon,
                      classes[item.title],
                    ].join(' ')}
                  >
                    <img
                      width={48}
                      height={51}
                      src={item.icon}
                      alt="shop-menu-item-icon"
                    />
                  </div>
                  <List dense>
                    {!isPageLoading && !isError && (
                      <>
                        {categories[item.title].slice(0, 15).map(category => (
                          <ListItem style={{ paddingLeft: 0 }}>
                            <Link
                              onClick={handleClose}
                              className={classes.shopMenuLink}
                              to={`/category/${item.title}_${category.name}`}
                            >
                              <ListItemText primary={category.name} />
                            </Link>
                          </ListItem>
                        ))}
                        <ListItem style={{ paddingLeft: 0 }}>
                          <Link
                            onClick={handleClose}
                            className={classes.shopMenuLink}
                            to={`/category/${item.title}`}
                          >
                            <ListItemText primary={t('shared.viewAll')} />
                          </Link>
                        </ListItem>
                      </>
                    )}
                  </List>
                </div>
              )
            })}
            <div className={classes.shopMenuColContainer}>
              <Link
                onClick={handleClose}
                className={classes.shopMenuLink}
                to="/brands"
              >
                <Typography
                  className={classes.shopMenuHeading}
                  variant="h1"
                  component="p"
                >
                  {t('shared.brands')}
                </Typography>
              </Link>
              <div
                className={[classes.shopMenuItemIcon, classes.brandsIcon].join(
                  ' '
                )}
              >
                <img
                  width={48}
                  height={51}
                  src={brandsIcon}
                  alt="brands-icon"
                />
              </div>
              <List dense>
                <ListItem style={{ paddingLeft: 0, marginBottom: 44 }}>
                  <Link
                    onClick={handleClose}
                    className={classes.shopMenuLink}
                    to="/brands"
                  >
                    {t('shared.brandAZ')}
                  </Link>
                </ListItem>
                {!isPageLoading &&
                  !isError &&
                  featuredBrands.slice(0, 15).map(brand => (
                    <ListItem style={{ paddingLeft: 0 }}>
                      <Link
                        className={classes.shopMenuLink}
                        onClick={handleClose}
                        to={`/featured-brands/${brand.brand_name.replace(
                          /\s+|\/|\\|#/g,
                          '-'
                        )}/${brand.brand_id}`}
                      >
                        <ListItemText primary={brand.brand_name} />
                      </Link>
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>
        </Box>
      )}
    </Box>
  )
}

export default withTranslation()(DropdownMenu)
