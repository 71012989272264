import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../assets/images/close.svg'
import { styles } from './styles'
import LoginComponent from '../signIn'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import SignInApp from '../app/signInApp'

interface SignInModalProps {
  handleClose: any
  openStatus: boolean
  loggedInUserInfo: any
  t: TFunction
}

const SignInModal = ({ handleClose, openStatus, t }: SignInModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const urlParams = new URLSearchParams(window.location.search)
  let isFromIos = urlParams.get('ios')
  isFromIos = null

  return isFromIos ? (
    <SignInApp handleClose={handleClose} openStatus={openStatus} />
  ) : (
    <Dialog
      fullScreen={fullScreen}
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <section className={classes.dialogContainer}>
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.dialogTitleSection}
        >
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
        </DialogTitle>
        <LoginComponent />
      </section>
    </Dialog>
  )
}

export default withTranslation()(SignInModal)
