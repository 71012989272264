import React, { useEffect, useState, useCallback } from 'react'
import { styles } from './styles'
import chirpyestTextLogo from './../../assets/images/chirpyest-text-logo.svg'
import {
  EXTENSION_ID,
  ROUTES,
  BREAKPOINTS,
  IOS_APP_LINK,
} from '../../constants'
import burgerMenuIcon from './../../assets/images/burger-menu.svg'
import ContentContainer from './../contentContainer'
import CHRHeaderDialog from './../headerDialog'
import SignInModal from './../signInModal'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Auth from '@aws-amplify/auth'
import { navigate, Link } from 'gatsby'
import { isUserAuthenticated } from './../../../utils/isUserAuthenticated'
import PopUp from '../popups/index'
import birdFace from './../../assets/images/Bird-face.png'
import searchIcon from './../../assets/images/search.svg'
import CloseIcon from '@material-ui/icons/Close'
import { sendExtensionMessage } from '../../../utils/extensionHelper'
import { useAppState } from '../../appState'
import { IconButton, Box, Typography } from '@material-ui/core'
import { useLocation } from '@reach/router'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DropdownMenu from './DropdownMenu'
import chrLogo from './../../assets/images/chirpyest.svg'

interface CHRHeaderProps {
  t: TFunction
  openSignInModal: boolean
  isJoinPage: boolean
  isScrollable?: boolean
  withBanner?: boolean
}

const CHRHeader = ({
  t,
  openSignInModal: openSignInModalProp,
  isJoinPage = false,
  isScrollable = false,
  withBanner = false,
}: CHRHeaderProps) => {
  const classes = styles()
  const [appState, dispatch] = useAppState()
  const [isOverlayOpen, setOverlayStatus] = useState(false)
  const [isSignInModal, setIsSignInModal] = useState(false)
  const isLoggedIn = appState.userId > 0
  const [isDropDownMenuOpen, setDropDownMenuStatus] = useState(false)
  const { pathname } = useLocation()
  const isDesktop = useMediaQuery(`@media (min-width: ${BREAKPOINTS.lg}px)`)
  const [isFullHeader, setIsFullHeader] = useState(
    window.pageYOffset > 60 ? false : true
  )
  const urlParams = new URLSearchParams(window.location.search)
  const signOutFromApp = urlParams.get('signOutFromApp')

  let timeout = null

  withBanner = isScrollable = false

  useEffect(() => {
    isUserAuthenticated().then(res => {
      openSignInModalProp && !res && setIsSignInModal(true)
    })
  }, [])

  useEffect(() => {
    if (signOutFromApp) {
      handleSignOut()
    }
  }, [signOutFromApp])

  useEffect(() => {
    setIsFullHeader(true)
  }, [pathname])

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset > 60) {
        setIsFullHeader(false)
      } else if (window.pageYOffset === 0) {
        setIsFullHeader(true)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const openMenuOverlay = useCallback(() => {
    setOverlayStatus(true)
  }, [])

  const openSignInModal = useCallback(() => {
    setIsSignInModal(true)
  }, [])

  const closeMenuOverlay = useCallback(() => {
    setOverlayStatus(false)
  }, [])

  const closeSignInModal = useCallback(() => {
    setIsSignInModal(false)
  }, [])

  const handleSignOut = async () => {
    dispatch({ type: 'SIGN_OUT' })
    navigate(ROUTES.home)
    window.localStorage.removeItem('user_id')
    window.localStorage.removeItem('movedItems')
    window.localStorage.removeItem('ig_token')
    window.localStorage.removeItem('referralId')
    window.localStorage.removeItem('profile')
    sendExtensionMessage({ action: 'signOut' })
    // keep compatibility with older extension
    if (window.chrome?.runtime?.sendMessage) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        { action: 'signOut' },
        (_response: any) => {}
      )
    }

    Auth.signOut()
  }

  const shoppingBoardLink = appState.username
    ? ROUTES.mainBoard.replace(':userName', appState.username)
    : ROUTES.settings

  const navStylesWhenLoggedIn = isLoggedIn ? classes.navUserIsLoggedIn : ''

  const showCloseIcon = pathname === '/search' && !isDesktop

  return (
    <header
      className={`${classes.header} ${
        isFullHeader ? classes.headerFull : classes.headerMinimized
      }`}
    >
      {withBanner && (
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
          position="sticky"
        >
          <Box display="flex">
            <img src={chrLogo} className={classes.logo} />
            <Box display="flex" flexDirection="column">
              <Typography className={classes.bannerMainText}>
                chirpyest
              </Typography>
              <Typography className={classes.bannerText}>
                open in the chirpyest app
              </Typography>
            </Box>
          </Box>
          <button
            className={classes.installButton}
            onClick={() => window.open(IOS_APP_LINK)}
          >
            GET
          </button>
        </Box>
      )}

      <div className={isJoinPage && classes.coloredHeader}>
        <ContentContainer>
          <nav
            className={`${classes.navigation} ${
              isScrollable
                ? classes.navigationMinimized
                : isFullHeader
                ? classes.navigationFull
                : classes.navigationMinimized
            }`}
          >
            {/*---------------- LEFT NAVIGATION ----------------*/}
            <div className={classes.navLeft}>
              {/*SHOP*/}
              <a className={`${classes.shop} ${classes.listItem}`}>
                <span
                  onClick={() => setDropDownMenuStatus(s => !s)}
                  onMouseOver={() => {
                    clearTimeout(timeout)
                    if (!isDropDownMenuOpen) {
                      setDropDownMenuStatus(true)
                    }
                  }}
                  onMouseLeave={() => {
                    timeout = setTimeout(() => {
                      setDropDownMenuStatus(false)
                    }, 1000)
                  }}
                  style={{
                    textDecoration: isDropDownMenuOpen ? 'underline' : 'none',
                    color: isJoinPage ? 'white' : 'black',
                  }}
                >
                  {t('shared.shop')}
                </span>
              </a>
              {/*BRANDS*/}
              <PopUp
                title={
                  <>
                    <img
                      src={birdFace}
                      alt={t('popUps.imageAlts.birdFace')}
                      className={classes.imageContainer}
                    />
                    <div className={classes.textContainer}>
                      {t('popUps.text.explore')}
                    </div>
                  </>
                }
                placement="bottom"
              >
                <Link
                  rel="preconnect"
                  to={ROUTES.brands}
                  className={`${classes.brands} ${classes.listItemLeft} ${
                    classes.listItem
                  } ${isJoinPage && classes.whiteText}`}
                >
                  {t('shared.brands')}
                </Link>
              </PopUp>
              {/*CHIRPYEST COMMUNITY*/}
              <Link
                rel="preconnect"
                to={ROUTES.theChirpyest}
                className={`${classes.listItem} ${classes.listItemLeft} ${
                  classes.theChirpyest
                } ${isJoinPage && classes.whiteText}`}
              >
                <span>{t('shared.theChirpyest')}</span>
              </Link>
            </div>
            {/*---------------- CENTER NAVIGATION ----------------*/}
            <div className={classes.navCenter}>
              <Link
                rel="preconnect"
                to={isLoggedIn ? ROUTES.member : ROUTES.home}
              >
                <img
                  src={chirpyestTextLogo}
                  alt={t('shared.chirpyest')}
                  className={` ${
                    isFullHeader
                      ? classes.chirpyestTextLogo
                      : classes.chirpyestTextLogoMinimized
                  } ${isJoinPage && classes.whiteLogo}`}
                />
              </Link>
            </div>
            {/*---------------- RIGHT NAVIGATION ----------------*/}
            <div className={classes.navRight}>
              <button
                onClick={openMenuOverlay}
                className={`${classes.menuContainer} ${classes.menuButton} ${classes.listItemRight}`}
              >
                <img
                  src={burgerMenuIcon}
                  alt={t('imageAlts.menu')}
                  className={`${classes.menuIcon} ${isJoinPage &&
                    classes.whiteLogo}`}
                />
              </button>
              {/*BLOG*/}
              {(isLoggedIn || pathname === '/lpv1') && (
                <a
                  href={ROUTES.blog}
                  target="_blank"
                  className={`${classes.listItem} ${classes.actionLinks} ${
                    classes.listItemRight
                  } ${classes.blog} ${isJoinPage && classes.whiteLogo}`}
                >
                  {t('shared.blog')}
                </a>
              )}
              {/*HOW IT WORKS*/}
              <Link
                rel="preconnect"
                to={ROUTES.howItWorks}
                className={`${classes.listItem} ${classes.actionLinks} ${
                  classes.listItemRight
                } ${isJoinPage && classes.whiteText}`}
              >
                {t('shared.howItWorks')}
              </Link>
              {/*JOIN*/}
              {!isLoggedIn && (
                <Link
                  rel="preconnect"
                  to={ROUTES.join}
                  className={`${classes.listItem} ${classes.actionLinks} ${
                    classes.listItemRight
                  } ${classes.join} ${isJoinPage && classes.whiteText}`}
                >
                  {t('shared.join')}
                </Link>
              )}
              {/*SIGN IN-OUT*/}
              {isLoggedIn ? (
                <button
                  onClick={handleSignOut}
                  className={`${classes.listItem} ${
                    classes.signInActions
                  } ${isJoinPage && classes.whiteLogo}`}
                  id="you-are-here"
                >
                  {t('header.signOut')}
                </button>
              ) : (
                <button
                  onClick={() => openSignInModal()}
                  className={`${classes.listItem} ${
                    classes.signInActions
                  } ${isJoinPage && classes.whiteText}`}
                >
                  {t('header.signIn')}
                </button>
              )}
            </div>
            {/*RIGHT SUB NAV*/}
            {isFullHeader && (
              <div className={classes.rightSubNavContainer}>
                <Link
                  rel="preconnect"
                  to={ROUTES.browserExtension}
                  className={`${classes.subListItemRight} ${
                    classes.shoppingBoard
                  } ${classes.listItemLeft} ${classes.yourShoppingBoard}
                  ${isJoinPage && classes.whiteText}
                  `}
                >
                  <span>{t('company.browserExtension.title')}</span>
                </Link>
              </div>
            )}
            {/*LEFT SUB NAV*/}
            {isLoggedIn && isFullHeader && (
              <div className={classes.leftSubNavContainer}>
                {/* create a “share link” */}
                <PopUp
                  title={
                    <>
                      <img
                        src={birdFace}
                        alt={t('popUps.imageAlts.birdFace')}
                        className={classes.imageContainer}
                      />
                      <div className={classes.textContainer}>
                        {t('popUps.text.wantToUse')}
                      </div>
                    </>
                  }
                  placement="bottom"
                >
                  <Link
                    rel="preconnect"
                    to={ROUTES.createShareLink}
                    className={`${classes.subListItem} ${classes.actionLinks} ${
                      classes.listItemRight
                    }
                    ${isJoinPage && classes.whiteText}`}
                  >
                    {t('createLink.createShareLink')}
                  </Link>
                </PopUp>
                {/*YOUR SHOPPING BOARD*/}
                <Link
                  rel="preconnect"
                  to={shoppingBoardLink}
                  className={`${classes.subListItem} ${classes.shoppingBoard} ${
                    classes.listItemLeft
                  } ${classes.yourShoppingBoard}
                  ${isJoinPage && classes.whiteText}
                  `}
                >
                  <span>{t('header.yourShoppingBoard')}</span>
                </Link>
                {/*YOUR ACCOUNT */}
                <PopUp
                  title={
                    <>
                      <img
                        src={birdFace}
                        alt={t('popUps.imageAlts.birdFace')}
                        className={classes.imageContainer}
                      />
                      <div className={classes.textContainer}>
                        {t('popUps.text.yourAccount')}
                      </div>
                    </>
                  }
                  placement="bottom"
                >
                  <Link
                    rel="preconnect"
                    to={ROUTES.cashback}
                    className={`${classes.subListItem} ${classes.actionLinks} ${
                      classes.listItemRight
                    } 
                    ${isJoinPage && classes.whiteText}`}
                  >
                    {t('header.yourAccount')}
                  </Link>
                </PopUp>
              </div>
            )}
            {/*SEARCH ICON*/}
            <IconButton
              style={{
                padding: 0,
              }}
              className={
                isJoinPage ? classes.searchOnColoredBg : classes.searchButton
              }
              onClick={() => {
                if (showCloseIcon) {
                  navigate(-1)
                } else {
                  navigate(ROUTES.search)
                }
              }}
            >
              {showCloseIcon ? (
                <CloseIcon color="primary" />
              ) : (
                <img
                  src={searchIcon}
                  alt={'searchIcon'}
                  height={16}
                  width={21}
                  className={isJoinPage && classes.whiteLogo}
                />
              )}
            </IconButton>
          </nav>
          <Box
            onMouseEnter={() => clearTimeout(timeout)}
            onMouseLeave={() => {
              timeout = setTimeout(() => {
                setDropDownMenuStatus(false)
              }, 200)
            }}
          >
            <DropdownMenu
              openStatus={isDropDownMenuOpen}
              handleClose={() => setDropDownMenuStatus(false)}
            />
          </Box>
        </ContentContainer>
      </div>
      <CHRHeaderDialog
        handleClose={closeMenuOverlay}
        openStatus={isOverlayOpen}
        openSignInModal={openSignInModal}
      />
      <SignInModal handleClose={closeSignInModal} openStatus={isSignInModal} />
    </header>
  )
}

export default withTranslation()(CHRHeader)
