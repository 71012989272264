import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  dialogContainer: {
    height: '100vh',
  },
  dialogTitle: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.common.black,
  },
  toolbar: {
    position: 'relative',
    padding: '20px 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontSize: '32px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    margin: 0,
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 40px',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
  text: {
    fontSize: '16px',
    margin: '20px 0 0',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: '0.156px',
    textTransform: 'lowercase',

    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  loginButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLORS.black,
  },
  credentials: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  credentialBox: {
    width: '250px',
    height: '40px',
    gap: 10,
    marginTop: 10,
  },
  errorMessage: {
    color: COLORS.watermelonPink,
    fontFamily: FONTS.Graphik.GraphikRegular,
    margin: 0,
    fontSize: '10px',
    marginTop: '10px',
  },
  requestError: {
    color: COLORS.watermelonPink,
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '10px',
    margin: 0,
  },
  passwordField: {
    display: 'flex',
    position: 'relative',
  },
  passVisibility: {
    position: 'absolute',
    right: 20,
    top: '30%',
    cursor: 'pointer',
  },
  forgotPassword: {
    color: COLORS.black,
    fontSize: 10,
    fontFamily: FONTS.Graphik.GraphikLight,
    margin: '20px 0',
    cursor: 'pointer',
  },
  recaptchaWrapper: {
    width: '251px',
    height: '64px',
    overflow: 'hidden',
  },
  recaptchaContainer: {
    width: '250px',
    transform: 'scale(0.83)',
    transformOrigin: '0 0',
  },
  eyeIcon: {
    color: theme.palette.common.black,
    width: 18,
    height: 18,
  },
  menuIcon: {
    width: '24px',
    height: '11.2px',
  },
}))
